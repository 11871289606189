<template>
    <div class="login container">
        <div class="row mt-5 justify-content-center">
            <div class="col-7">
                <div class="card mb-3" style="border-style: solid; border-color:#09CCB8; border-radius: 12px;">
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-3 text-center">
                                <div v-bind:class="{card_active:display_NatureHumanCard, card_noactive:display_HealthCard}">
                                    <span @click="UseNatureHumanCard(this);">自然人憑證</span>
                                </div>
                                <!--<div style="border-style: hidden hidden solid hidden; border-color:#09CCB8; border-width:thick;">
                                    <span style="cursor: pointer; color: #000000;" @click="UseNatureHumanCard(this);">自然人憑證</span>
                                </div>-->
                            </div>
                            <div class="col-3 text-center">
                                <div v-bind:class="{card_noactive:display_NatureHumanCard, card_active:display_HealthCard}">
                                    <span @click="UseHealthCard(this);">健保卡</span>
                                </div>
                                <!--<div style="border-style: hidden hidden solid hidden; border-color:#b4b4b4; border-width:thick;">
        <span style="cursor:pointer; color:#b4b4b4;" @click="UseHealthCard(this);">健保卡</span>
    </div>-->
                            </div>
                            <div class="col-3 text-center">
                                <div style="border-style: hidden hidden solid hidden; border-color:#b4b4b4;  border-width:thick;">
                                    <span style="cursor:pointer; color:#b4b4b4;">醫事人員卡</span>
                                </div>
                            </div>
                        </div>
                        <!--自然人-->
                        <div class="row mt-5 justify-content-center" v-show="display_NatureHumanCard">
                            <div class="col-10">
                                <div class="form-group row">
                                    <label for="inputId" class="col-sm-6 col-form-label text-right">身份證號：</label>
                                    <div class="col-sm-6">
                                        <input type="text" class="form-control" id="inputId" v-model="inputId" placeholder="" style="border-color:#09CCB8;">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="inputId" class="col-sm-6 col-form-label text-right">自然人憑證PIN碼：</label>
                                    <div class="col-sm-6">
                                        <input type="password" class="form-control" id="inputPIN" v-model="inputPIN" placeholder="" style="border-color:#09CCB8;">
                                    </div>
                                </div>
                            </div>
                            <div class="col-2"></div>
                        </div>
                        <div class="row mt-4 justify-content-center" v-show="display_NatureHumanCard">
                            <div class="col-12 text-center">
                                請插入自然人憑證後按確認
                            </div>
                        </div>
                        <div class="row mt-3 mb-3 justify-content-center" v-show="display_NatureHumanCard">
                            <div class="col-4">
                                <button type="button" class="btn btn-primary btn-block" @click="Button_click_NatureHumanCard_Form()"
                                        style="padding:12px 0; background: linear-gradient(90deg, #0A5474 0%, #05909D 100%); border-radius: 32px;">
                                    確認
                                </button>
                                <!--<button type="button" class="btn btn-primary btn-block" @click="TestApi()"
                                    style="padding:12px 0; background: linear-gradient(90deg, #0A5474 0%, #05909D 100%); border-radius: 32px;">確認</button>-->
                            </div>
                        </div>
                        <!--健保卡-->
                        <div class="row mt-5 justify-content-center" v-show="display_HealthCard">
                            <div class="col-10">
                               <div class="form-group row">
                                   <label for="inputId" class="col-sm-6 col-form-label text-right">健保卡網路服務註冊密碼：</label>
                                    <div class="col-sm-6">
                                        <input type="password" class="form-control" id="inputPwd" v-model="inputPwd" placeholder="" style="border-color:#09CCB8;">
                                    </div>
                                </div>
                            </div>
                            <div class="col-2"></div>
                        </div>
                        <div class="row mt-4 justify-content-center" v-show="display_HealthCard">
                            <div class="col-12 text-center">
                                請插入健保卡後按確認
                            </div>
                        </div>
                        <div class="row mt-3 mb-3 justify-content-center" v-show="display_HealthCard">
                            <div class="col-4">
                                <button type="button" class="btn btn-primary btn-block" @click="Button_click_HealthCard_Form()"
                                        style="padding:12px 0; background: linear-gradient(90deg, #0A5474 0%, #05909D 100%); border-radius: 32px;">
                                    確認
                                </button>
                                <!--<button type="button" class="btn btn-primary btn-block" @click="TestApi()"
                style="padding:12px 0; background: linear-gradient(90deg, #0A5474 0%, #05909D 100%); border-radius: 32px;">確認</button>-->
                            </div>
                        </div>
                        <div class="row mt-3 mb-3 justify-content-center">
                            <div class="col-10 text-right" style="color:#086F87;">
                                電腦設定
                                <i class="fas fa-caret-right"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    //其它共用
    import CA2 from "../shared/CA2Common/CA2.js";
    import Swal from 'sweetalert2';
    import $ from 'jquery';

    export default {
        name: "CA2TestPage", //測試網頁元件名稱
        //網頁變數宣告區
        data() {
            return {
                data_headerMessage: "Internet環境卡片與讀卡機測試網頁", //標題
                data_btn_disabled: false, //所有讀卡按鈕停用控制
                //健保卡
                data_HealthCardGetPW: null,
                data_HealthCardID: null,
                data_HealthCardName: null,
                data_HealthCardBirthday: null,
                data_HealthCardSex: null,
                data_HealthCardIssueDate: null,
                data_HealthCardCardSN: null,
                data_HealthCardCancellation: null,
                data_HealthCardSignData: null,
                //自然人憑證卡
                data_NatureHumanCardID: null,
                data_NatureHumanCardID4: null,
                data_NatureHumanCardName: null,
                data_NatureHumanCardGetX509SignatureCert: null,
                data_NatureHumanCardSignData: null,
                //輸入參數
                inputId: null,
                inputPIN: null,
                inputPwd: null,
                //顯示參數
                display_NatureHumanCard: true,
                display_HealthCard: false,
            };
        },
        mounted() {
            sessionStorage.clear();
        },
        //網頁方法宣告區
        methods: {
            UseNatureHumanCard(e) {
                var elm = $(e);
                console.log(elm);
                this.display_NatureHumanCard = true;
                this.display_HealthCard = false;
            },
            UseHealthCard(e) {
                var elm = $(e);
                console.log(elm);
                this.display_NatureHumanCard = false;
                this.display_HealthCard = true;
            },
            Button_click_HealthCard_Form() {
                var self = this;
                try {
                    if (self.inputPwd == null) {
                        throw new Error("請輸入健保卡網路服務註冊密碼");
                    }
                    //self.Button_click_HealthCard_FormCheck();
                    //假設驗簽成功redirect到自費網站
                    //location.href = "http://localhost:13659/sso/login/1101100011";
                    location.href = "http://dev.markyoyo.com/sso/login/1101100011";

                } catch (ex) {
                    Swal.fire({
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        confirmButtonText: '確定',
                        confirmButtonColor: "#09CCB8",
                        html: '<span style="color:#666666; font-weight:bold;">' + ex.message + '</span>',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            return false;
                        }
                    });
                }
            },
            Button_click_HealthCard_FormCheck() {
                //var self = this;
                //var objHealthCard = CA2.CA2Factory.CreateHealthCard(self.inputPwd); //健保卡


            },
            //健保讀卡機(雲端控軟體).SAM卡[IE瀏覽器+多元瀏覽器]
            Button_click_HealthCard() {
                var self = this;
                self.data_btn_disabled = true; //停用按鈕，此為避免重複執行
                var UserInputPW = prompt("請輸入健保卡網路服務註冊密碼", "999999");
                var objHealthCard = CA2.CA2Factory.CreateHealthCard(UserInputPW); //健保卡
                //讀卡成功
                function CardSuccess() {
                    self.data_btn_disabled = false; //啟用按鈕
                    //資料呈現
                    alert(
                        "SAM卡[ID:" +
                        objHealthCard.ID() +
                        "]" +
                        "[Name:" +
                        objHealthCard.Name() +
                        "]" +
                        "[Birthday:" +
                        objHealthCard.Birthday() +
                        "]" +
                        "[Sex:" +
                        objHealthCard.Sex() +
                        "]" +
                        "[IssueDate:" +
                        objHealthCard.IssueDate() +
                        "]" +
                        "[CardSN:" +
                        objHealthCard.CardSN() +
                        "]" +
                        "[Cancellation:" +
                        objHealthCard.Cancellation() +
                        "]" +
                        "[SignData:" +
                        objHealthCard.SignData() +
                        "]"
                    );
                    //UI實作button submit動作(含驗簽)
                    // $("#<%=Button33.clientid %>").click();
                }
                //讀卡失敗
                function CardError(e) {
                    self.data_btn_disabled = false; //啟用按鈕
                    CA2.CA2Message.Show(e);
                }
                // 其它瀏覽器使用WebSocket技術
                try {
                    objHealthCard.WebSocketAsync({
                        //WebSocket中的功能執行成功
                        success: function () {
                            //簽章資料設定
                            self.data_HealthCardID = objHealthCard.ID();
                            self.data_HealthCardName = objHealthCard.Name();
                            self.data_HealthCardBirthday = objHealthCard.Birthday();
                            self.data_HealthCardSex = objHealthCard.Sex();
                            self.data_HealthCardIssueDate = objHealthCard.IssueDate();
                            self.data_HealthCardCardSN = objHealthCard.CardSN();
                            self.data_HealthCardCancellation = objHealthCard.Cancellation();
                            self.data_HealthCardSignData = objHealthCard.SignData();
                            self.data_HealthCardGetPW = objHealthCard.GetPW();
                            CardSuccess();
                        },
                        error: function (e) {
                            CardError(e);
                        },
                    });
                } catch (e) {
                    CardError(e);
                }
            },
            //驗簽-健保讀卡機(雲端控軟體).SAM卡[IE瀏覽器+多元瀏覽器]
            VerifyHealthCard() {
                var self = this;
                self.data_btn_disabled = true; //停用按鈕，此為避免重複執行
                let model = {
                    LoginID: this.data_HealthCardID,
                    NHIICCSignature: this.data_HealthCardSignData,
                    HealthCardPW: this.data_HealthCardGetPW,
                    HealthCardCardSN: this.data_HealthCardCardSN,
                };
                this.$http
                    .post(
                        process.env.VUE_APP_API + "api/ihke000x/ihke000xs01/VerifyHealthCard",
                        model
                    )
                    .then(
                        (success) => {
                            if (success.data.PxxtJsonException != null) {
                                success.data.PxxtJsonException.errors.forEach((element) => {
                                    CA2.CA2Message.Show(JSON.parse(element.errorMessage));
                                });
                            } else {
                                sessionStorage.setItem(
                                    "token",
                                    JSON.parse(JSON.stringify(success.data))
                                );
                                alert("成功");
                            }
                        },
                        (error) => {
                            console.log(error.data);
                            alert("系統發生未預期錯誤");
                        }
                    )
                    .finally(() => {
                        self.data_btn_disabled = false; //啟用按鈕
                    });
            },
            TestApi() {
                $.ajax({
                    method: "GET",
                    async: false,
                    url: "/api/test/index",
                    dataType: 'json', // 預期從server接收的資料型態
                    contentType: 'application/json',//; charset=utf-8', // 要送到server的資料型態
                    data: {}, //data: JSON.stringify(model),
                    success: function (data) {
                        if (data.success) {
                            Swal.fire('Api Test OK');
                        }
                    },
                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        console.log('Error-->[textStatus:' + textStatus + '][errorThrown:' + errorThrown + '][XMLHttpRequest.responseText:' + XMLHttpRequest.responseText + ']');
                        Swal.fire(textStatus);
                    }
                });

            },
            Button_click_NatureHumanCard_FormCheck() {
                var self = this;
                var objNatureHumanCard = CA2.CA2Factory.CreateNatureHumanCard(self.inputPIN, self.inputId); //自然人憑證卡
                //讀卡成功
                function CardSuccess() {
                    self.data_btn_disabled = false; //啟用按鈕
                    //資料呈現
                    alert(
                        "{自然人憑證卡[ID:" +
                        objNatureHumanCard.ID() +
                        "]" +
                        "[ID4:" +
                        objNatureHumanCard.ID4() +
                        "]" +
                        "[Name:" +
                        objNatureHumanCard.Name() +
                        "]" +
                        "[GetX509SignatureCert:" +
                        objNatureHumanCard.GetX509SignatureCert() +
                        "]" +
                        "[SignData:" +
                        objNatureHumanCard.SignData() +
                        "]}"
                    );
                }
                //讀卡失敗
                function CardError(e) {
                    self.data_btn_disabled = false; //啟用按鈕
                    CA2.CA2Message.Show(e);
                }
                try {
                    //簽章資料設定
                    self.data_NatureHumanCardID = objNatureHumanCard.ID();
                    self.data_NatureHumanCardID4 = objNatureHumanCard.ID4();
                    self.data_NatureHumanCardName = objNatureHumanCard.Name();
                    self.data_NatureHumanCardGetX509SignatureCert = objNatureHumanCard.GetX509SignatureCert();
                    self.data_NatureHumanCardSignData = objNatureHumanCard.SignData();
                    CardSuccess();

                } catch (e) {
                    CardError(e);
                }

            },
            //改寫form模式
            Button_click_NatureHumanCard_Form() {
                var self = this;
                try {
                    if (self.inputId == null) {
                        throw new Error("請輸入身分證字號");
                    }
                    if (self.inputPIN == null) {
                        throw new Error("請輸入自然人憑證PIN碼");
                    }
                    //self.Button_click_NatureHumanCard_FormCheck();
                    //假設驗簽成功redirect到自費網站
                    //location.href = "http://localhost:13659/sso/login/1101100011";
                    location.href = "http://dev.markyoyo.com/sso/login/1101100011";

                } catch (ex) {
                    Swal.fire({
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        confirmButtonText: '確定',
                        confirmButtonColor: "#09CCB8",
                        html: '<span style="color:#666666; font-weight:bold;">' + ex.message + '</span>',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            return false;
                        }
                    });
                }
            },
            //晶片讀卡機.自然人憑證卡
            Button_click_NatureHumanCard() {
                var self = this;
                self.data_btn_disabled = true; //停用按鈕，此為避免重複執行
                var UserInputPIN = prompt("請輸入卡片PIN碼", "999999");
                var UserInputID = prompt("請輸入身分證號", "Z2******65");
                var objNatureHumanCard = CA2.CA2Factory.CreateNatureHumanCard(UserInputPIN, UserInputID); //自然人憑證卡
                //讀卡成功
                function CardSuccess() {
                    self.data_btn_disabled = false; //啟用按鈕
                    //資料呈現
                    alert(
                        "{自然人憑證卡[ID:" +
                        objNatureHumanCard.ID() +
                        "]" +
                        "[ID4:" +
                        objNatureHumanCard.ID4() +
                        "]" +
                        "[Name:" +
                        objNatureHumanCard.Name() +
                        "]" +
                        "[GetX509SignatureCert:" +
                        objNatureHumanCard.GetX509SignatureCert() +
                        "]" +
                        "[SignData:" +
                        objNatureHumanCard.SignData() +
                        "]}"
                    );
                }
                //讀卡失敗
                function CardError(e) {
                    self.data_btn_disabled = false; //啟用按鈕
                    CA2.CA2Message.Show(e);
                }
                try {
                    //簽章資料設定
                    self.data_NatureHumanCardID = objNatureHumanCard.ID();
                    self.data_NatureHumanCardID4 = objNatureHumanCard.ID4();
                    self.data_NatureHumanCardName = objNatureHumanCard.Name();
                    self.data_NatureHumanCardGetX509SignatureCert = objNatureHumanCard.GetX509SignatureCert();
                    self.data_NatureHumanCardSignData = objNatureHumanCard.SignData();
                    CardSuccess();
                } catch (e) {
                    CardError(e);
                }
            },
            //驗簽-晶片讀卡機.自然人憑證卡
            VerifyNatureHumanCard() {
                var self = this;
                self.data_btn_disabled = true; //停用按鈕，此為避免重複執行
                let model = {
                    LoginID: this.data_NatureHumanCardID,
                    CGSignature: this.data_NatureHumanCardSignData,
                };
                this.$http
                    .post(
                        process.env.VUE_APP_API + "api/ihke000x/ihke000xs01/VerifyNatureHumanCard",
                        model
                    )
                    .then(
                        (success) => {
                            if (success.data.PxxtJsonException != null) {
                                success.data.PxxtJsonException.errors.forEach((element) => {
                                    CA2.CA2Message.Show(JSON.parse(element.errorMessage));
                                });
                            } else {
                                sessionStorage.setItem(
                                    "token",
                                    JSON.parse(JSON.stringify(success.data))
                                );
                                alert("成功");
                            }
                        },
                        (error) => {
                            console.log(error.data);
                            alert("系統發生未預期錯誤");
                        }
                    )
                    .finally(() => {
                        self.data_btn_disabled = false; //啟用按鈕
                    });
            },
            //讀取jwt值
            Buttonjwt_click() {
                var self = this;
                self.data_btn_disabled = true; //停用按鈕，此為避免重複執行
                this.$http
                    .get(process.env.VUE_APP_API + "api/ihke000x/ihke000xs01/GetClaims")
                    .then(
                        (success) => {
                            var msg = "";
                            success.data.forEach((e) => {
                                msg += "[" + e.type + ":" + e.value + "]";
                            });
                            alert(msg);
                        },
                        (error) => {
                            console.log(error.data);
                            alert("系統發生未預期錯誤");
                        }
                    )
                    .finally(() => {
                        self.data_btn_disabled = false; //啟用按鈕
                    });
            },
        },
    };
</script>

<style scoped>
    .card_active {
        border-style: hidden hidden solid hidden;
        border-color: #09CCB8;
        border-width: thick;
    }
        .card_active span {
            cursor: pointer;
            color: #000000;
        }


    .card_noactive {
        border-style: hidden hidden solid hidden;
        border-color: #b4b4b4;
        border-width: thick;
    }
        .card_noactive span {
            cursor: pointer;
            color: #b4b4b4;
        }
</style>