<template>
  <div class="about">
    <h1>Vue 3 + .NET Core Proxy Example</h1>
    <button @click="fetchData">Fetch Data from .NET Core</button>
    <p v-if="message">{{ message }}</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      message: '',
    };
  },
  methods: {
    async fetchData() {
      try {
        const response = await axios.get('/api/test/index');
        this.message = response.data.message;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
  },
};
</script>